import React, { FC, useState } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

import { Button } from "@components/Button";
import TrashIcon from "@components/Icons/TrashIcon";
import Modal from "@components/Modals/SmallModal";

interface InfoModalProps {
  visible: boolean;
  toggle: () => void;
  settings: {
    Icon: FC<SVGIconProps>;
    heading: string;
    hasBack?: boolean;
    backText?: string;
    positiveAction?: boolean;
  };
  children: React.ReactNode;
  onActionClick?: () => Promise<void>;
  actionText: string;
  hasOverflow?: boolean;
  autoClose?: boolean;
  heapEventName?: string;
}

export const InfoModal: FC<InfoModalProps> = ({
  visible,
  toggle,
  settings,
  children,
  onActionClick,
  actionText,
  hasOverflow,
  autoClose,
  heapEventName,
}) => {
  const {
    Icon,
    heading,
    hasBack,
    backText = "Back",
    positiveAction = false,
  } = settings;

  const [loading, setLoading] = useState(false);

  return (
    <Modal show={visible} toggleShow={toggle} heapModalName="info-modal">
      <div className="flex flex-col max-h-full">
        <div
          className={classNames(
            "mb-8 mt-8 flex flex-col",
            !hasOverflow && "overflow-hidden"
          )}
        >
          <span
            className={classNames(
              "rounded-full inline-block p-4 mb-3 mx-auto",
              positiveAction
                ? "bg-blue-950 text-blue-500"
                : "bg-peach-950 text-peach-400"
            )}
          >
            <Icon className="w-8 h-8" />
          </span>
          <h3 className="text-black-ink text-center text-3xl mb-2">
            {heading}
          </h3>
          {children}
        </div>
        <div className="flex flex-col justify-between">
          <Button
            data-heap-event-name={heapEventName}
            className={classNames(hasBack && "mb-2")}
            primary
            onClick={async () => {
              if (onActionClick) {
                setLoading(true);
                await onActionClick();
                setLoading(false);
              }
              if (autoClose) toggle();
            }}
            isLoading={loading}
            disabled={loading || !onActionClick}
          >
            {actionText}
          </Button>
          {hasBack && <Button onClick={toggle}>{backText}</Button>}
        </div>
      </div>
    </Modal>
  );
};

export const WarningModal: FC<InfoModalProps> = (props) => {
  return (
    <InfoModal
      {...props}
      settings={{ Icon: TrashIcon, heading: "Are you sure?", hasBack: true }}
    />
  );
};
