import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import biteSize from "byte-size";
import classNames from "classnames";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { isNumber } from "lodash";
import { File as FileType } from "types/db/file";
import { v4 as uuid } from "uuid";

import { useAuth } from "@contexts/auth";
import analytics from "@lib/analytics";
import { compatDB as db, compatStorage as storage } from "@lib/firebase-config";

import BlockIcon from "@components/Icons/BlockIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import DocumentIcon from "@components/Icons/DocumentIcon";
import ReloadIcon from "@components/Icons/ReloadIcon";
import TrashIcon from "@components/Icons/TrashIcon";

import { FileInput } from "./Form/FileInput";
import { InfoModal } from "./Modal/InfoModal";
import { Button } from "./Button";
import LoadingSpinner from "./LoadingSpinner";
import { Modal, ModalContent, ModalTitle } from "./Modal";

interface UploadModalProps {
  heapEventName: string;
  uploadPath: string;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  libraryPath?: string;
}

export const UploadModal: React.FC<UploadModalProps> = ({
  open = false,
  toggleModal,
  uploadPath,
  heapEventName,
  libraryPath,
}) => {
  const [files, setFiles] = useState<UploadFileInfo[]>([]);

  const [showClosingWarningModal, setShowClosingWarningModal] = useState(false);

  const uploadingInProgress = useMemo(
    () => files.filter((f) => isNumber(f.uploading)),
    [files]
  );

  const closeModal = () => {
    setFiles([]);
    toggleModal();
  };

  const safeToggleModal = () => {
    if (open && uploadingInProgress.length) {
      uploadingInProgress.forEach((f) => f.uploadTask.pause());
      setShowClosingWarningModal(true);
    } else {
      closeModal();
    }
  };

  const onDiscard = () => {
    uploadingInProgress.forEach((f) => f.uploadTask.cancel());
    setShowClosingWarningModal(false);
    closeModal();
  };

  const onResume = () => {
    uploadingInProgress.forEach((f) => f.uploadTask.resume());
    setShowClosingWarningModal(false);
  };

  return (
    <>
      <Modal show={open} toggleShow={safeToggleModal} maxWidth="max-w-3xl">
        <UploadModalContent
          uploadPath={uploadPath}
          libraryPath={libraryPath}
          closeModal={closeModal}
          files={files}
          setFiles={setFiles}
          heapEventName={heapEventName}
        />
      </Modal>
      <InfoModal
        settings={{ Icon: BlockIcon, heading: "Cancel upload?", hasBack: true }}
        visible={showClosingWarningModal}
        toggle={() => {
          setShowClosingWarningModal(!showClosingWarningModal);
          onResume();
        }}
        actionText="Cancel Upload"
        onActionClick={onDiscard}
      >
        <p className="text-center">
          Are you sure you want to discard this file
        </p>
      </InfoModal>
    </>
  );
};

const sizeInMB = (size: number) => size / (1024 * 1024);
const limitInMB = 500;

interface UploadModalContentProps {
  files: UploadFileInfo[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFileInfo[]>>;
  heapEventName: string;
  uploadPath: string;
  closeModal: () => void;
  libraryPath?: string;
}

export const UploadModalContent: React.FC<UploadModalContentProps> = ({
  uploadPath,
  closeModal,
  files,
  setFiles,
  heapEventName,
  libraryPath,
}) => {
  const { userId } = useAuth();

  const getFirestoreData = useCallback(() => {
    const base = {
      createdAt: new Date(),
      updatedAt: new Date(),
      status: "private",
      coachUserId: userId,
      sharedWith: uploadPath === `/users/${userId}/files/` ? [] : null,
    };
    if (!libraryPath) return base;
    return {
      ...base,
      path: libraryPath,
    };
  }, [userId, uploadPath, libraryPath]);

  const someUploading = useMemo(
    () => files.some((f) => !f.uploadedFile && !f.cancelled && !f.error),
    [files]
  );

  return (
    <div className="flex flex-col">
      <ModalTitle className="flex justify-between items-center mb-6">
        <div>
          <h3 className="text-3xl mb-1 leading-tight">Upload files</h3>
        </div>
      </ModalTitle>

      <ModalContent className="w-full">
        <InlineUpload
          multiple={true}
          files={files}
          setFiles={setFiles}
          uploadPath={uploadPath}
          getFirestoreData={getFirestoreData}
        />
        <div className="flex mt-4 space-x-4 justify-end">
          {/* <Button
            disabled={someUploading || !files.length}
            // onClick={onSave}
            data-heap-event-name={heapEventName}
          >
            Share with clients
          </Button> */}
          <Button
            primary
            disabled={someUploading || !files.length}
            onClick={() => {
              analytics.track({
                event: heapEventName,
                properties: {
                  count: files.length,
                },
              });
              closeModal();
            }}
            data-heap-event-name={heapEventName}
          >
            Done
          </Button>
        </div>
      </ModalContent>
    </div>
  );
};

export type UploadFileInfo = {
  uploading: number | null;
  uploadedFile?: FileType;
  uploadTask?: firebase.storage.UploadTask;
  error?: string;
  fileInput?: File;
  itemId: string;
  docId?: string;
  cancelled?: boolean;
};

export interface InlineUploadProps {
  uploadPath: string;
  files: UploadFileInfo[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFileInfo[]>>;
  vertical?: boolean;
  readonly?: boolean;
  multiple?: boolean;
  getFirestoreData?: () => any;
  onClickDelete?: () => void;
  variant?: Variant;
  placeholder?: string;
  verticalHeight?: keyof typeof HEIGHTS;
  preview: "none" | "regular" | "plain" | "covered";
  customButton?: ReactNode;
}

const styles = {
  auto: {
    descriptionText: "text-foreground/50",
    text: "text-foreground",
    background: "bg-foreground/7",
    actionText: "text-accent",
    borderLight: "border-foreground/7",
    borderDark: "border-foreground/20",
    deleteClassName: "",
    preview: "",
  },
  light: {
    descriptionText: "text-grey-500",
    text: "text-black-ink",
    background: "bg-grey-950",
    actionText: "text-action-500",
    borderLight: "border-grey-950",
    borderDark: "border-grey-900",
    deleteClassName: "",
    preview: "",
  },
  goFlow: {
    descriptionText: "text-grey-500",
    text: "text-white",
    preview: "rounded-xl bg-grey-100 !h-16",
    background:
      "!flex-row !p-0 h-full gap-2 group border border-dashed border-grey-300",
    actionText: "text-action-500",
    borderLight: "border-grey-500",
    borderDark: "border-grey-300 !border",
    deleteClassName:
      "absolute top-2 right-2 hidden group-hover:block transition ease-in-out",
  },
};

const HEIGHTS = {
  small: "!h-16",
  regular: "h-20",
  large: "h-32",
};

// TODO create context for prop drilling
export const InlineUpload: React.FC<InlineUploadProps> = ({
  uploadPath,
  files,
  setFiles,
  vertical,
  readonly,
  multiple = false,
  getFirestoreData,
  onClickDelete,
  variant = "auto",
  placeholder,
  verticalHeight = "regular",
  preview = "none",
  customButton,
}) => {
  const onDrop = (acceptedFiles: any) =>
    onFileSelected({ target: { files: acceptedFiles } });
  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: readonly,
  });

  const updateFile = useCallback(
    (index: number, patch: Partial<UploadFileInfo>) => {
      setFiles((ex) =>
        ex.map((f, i) => (i === index ? { ...f, ...patch } : f))
      );
    },
    [setFiles]
  );

  const uploadFile = useCallback(
    (fileData: UploadFileInfo, i: number) => {
      const file = fileData.fileInput;

      if (!file) return;

      const fileName = file.name;

      const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
      const metadata = { contentDisposition: `filename="${fileName}"` };

      const itemId = fileData.itemId;

      const uploadTask = storage
        .ref()
        .child(`${uploadPath}${itemId}`)
        .put(file, metadata);

      updateFile(i, { uploadTask, uploading: 0 });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          updateFile(i, {
            uploading: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          });
        },
        (error: any) => {
          if (error.code === "storage/canceled") return;

          updateFile(i, { error: error.message, uploading: null });
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          const { contentType, size, fullPath, bucket } =
            await uploadTask.snapshot.ref.getMetadata();
          const fileData = {
            fileName,
            size,
            contentType,
            url,
            extension,
            fullPath,
            bucket,
            itemId,
          };
          let docId;
          if (getFirestoreData) {
            const doc = await addDoc(collection(db, uploadPath), {
              ...fileData,
              ...getFirestoreData(),
            });
            docId = doc.id;
          }

          updateFile(i, {
            uploadedFile: fileData,
            docId,
            uploading: null,
          });
        }
      );
    },
    [getFirestoreData, updateFile, uploadPath]
  );

  const onFileSelected = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files?.length) return;

      const filesData = [...files]
        .filter((fileInpit) => sizeInMB(fileInpit.size) < limitInMB)
        .map(
          (fileInput) =>
            ({
              fileInput,
              uploading: 0,
              itemId: uuid(),
            }) as UploadFileInfo
        );

      setFiles(filesData);

      for (let i = 0; i < filesData.length; i++) {
        uploadFile(filesData[i], i);
      }
    },
    [setFiles, uploadFile]
  );

  const onDeleteCallbacks = useMemo(
    () =>
      files.map((f, i) => async () => {
        let path = "";
        setFiles((ex) => ex.filter((_, index) => i !== index));
        if (!f.uploadedFile) return;
        if (f.uploadedFile.fullPath) path = f.uploadedFile.fullPath;
        if (f.itemId && uploadPath) path = `${uploadPath}${f.itemId}`;
        if (path) await storage.ref().child(path).delete();
        if (f.docId) await deleteDoc(doc(db, `${uploadPath}${f.docId}`));
        onClickDelete && onClickDelete();
      }),
    [files, setFiles, uploadPath]
  );

  const onCancelCallbacks = useMemo(
    () =>
      files.map((f, i) => async () => {
        setFiles((ex) =>
          ex.map((f, index) =>
            index === i ? { ...f, uploading: null, cancelled: true } : f
          )
        );
        f.uploadTask?.cancel();
      }),
    [files, setFiles]
  );

  const onRetryCallbacks = useMemo(
    () =>
      files.map((f, i) => async () => {
        updateFile(i, { cancelled: null, error: null });
        uploadFile(f, i);
      }),
    [files, updateFile, uploadFile]
  );

  if (!files.length) {
    return (
      <div
        {...getRootProps({
          className: classNames(
            "rounded-2xl p-4 items-center flex relative border-2 border-dashed",
            vertical
              ? `text-base ${HEIGHTS[verticalHeight]}`
              : "justify-center flex-col h-64 text-xl",
            isDragActive && "bg-accent/10",
            styles[variant].text,
            styles[variant].borderDark
          ),
        })}
      >
        {!customButton && (
          <DocumentIcon
            className={classNames(
              styles[variant].text,
              vertical ? "w-6 h-6 shrink-0" : "w-8 h-8"
            )}
          />
        )}
        <div className={classNames("min-w-0", !vertical && "mt-3")}>
          <UploadButton
            readonly={readonly}
            vertical={vertical}
            onFileSelected={onFileSelected}
            multiple={multiple}
            variant={variant}
            placeholder={
              placeholder || `Drop your file${multiple ? "(s)" : ""} here or `
            }
            customButton={customButton}
          />
        </div>
      </div>
    );
  }

  const isCovered = preview === "covered";

  if (!multiple) {
    const file = files[0];
    const { error, uploading, uploadedFile } = file;

    if ((preview === "plain" || isCovered) && uploadedFile) {
      return (
        <div className="relative w-full h-full group">
          <div
            className={classNames(
              "bg-contain bg-center bg-no-repeat w-full h-full",
              styles[variant].preview,
              isCovered && "!bg-cover"
            )}
            style={{
              backgroundImage: `url("${uploadedFile.url}")`,
            }}
          />

          <div
            className={classNames(
              "absolute top-0 right-0",
              styles[variant].deleteClassName
            )}
          >
            <Button white square onClick={onDeleteCallbacks[0]}>
              <TrashIcon className="" />
            </Button>
          </div>
        </div>
      );
    }

    if (preview === "regular" && uploadedFile) {
      return (
        <div
          className={classNames(
            "rounded-2xl items-center flex relative p-6 flex-col",
            styles[variant].background
          )}
        >
          <div
            className={classNames(
              "bg-contain bg-center bg-no-repeat h-12 w-full",
              styles[variant].background,
              styles[variant].preview
            )}
            style={{
              backgroundImage: `url("${uploadedFile.url}")`,
            }}
          />

          <div className={styles[variant].descriptionText}>
            {uploadedFile.fileName}
          </div>
          <div
            className={classNames(
              "absolute top-8 right-4",
              styles[variant].deleteClassName
            )}
          >
            <Button white square onClick={onDeleteCallbacks[0]}>
              <TrashIcon className="" />
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames(
          "rounded-2xl p-4 items-center flex relative",
          !error &&
            (uploading !== null
              ? `${styles[variant].background} border-2 ${styles[variant].borderLight}`
              : `border-2 border-dashed ${styles[variant].borderDark}`),
          error && "bg-error/7",
          styles[variant].text,
          vertical ? "text-base h-20" : "justify-center flex-col text-xl",
          verticalHeight ? HEIGHTS[verticalHeight] : "h-64"
        )}
      >
        <Icon
          uploading={uploading}
          uploadedFile={uploadedFile}
          vertical={vertical}
          isGoFlow={variant === "goFlow"}
        />
        <div className={classNames("min-w-0", !vertical && "mt-3")}>
          <FileInfo
            readonly={readonly}
            uploading={uploading}
            uploadedFile={uploadedFile}
            onDelete={onDeleteCallbacks[0]}
            vertical={vertical}
            variant={variant}
          />
        </div>
      </div>
    );
  }

  if (multiple) {
    return (
      <div
        className={classNames(
          "rounded-2xl p-4 flex flex-col text-black-ink relative border-2 border-dashed space-y-2"
        )}
      >
        {files.map((file, i) => (
          <FileListItemInfo
            key={file.itemId}
            file={file}
            variant={variant}
            onDelete={onDeleteCallbacks[i]}
            onCancel={onCancelCallbacks[i]}
            onRetry={onRetryCallbacks[i]}
          />
        ))}
      </div>
    );
  }

  return null;
};

type Variant = "auto" | "light" | "goFlow";

interface FileInfoProps {
  uploading: number | null;
  uploadedFile?: FileType;
  onDelete: () => void;
  vertical?: boolean;
  readonly?: boolean;
  variant?: Variant;
}

export const FileInfo: React.FC<FileInfoProps> = ({
  uploading,
  uploadedFile,
  onDelete,
  vertical,
  readonly,
  variant = "auto",
}) => {
  const isGoFlow = variant === "goFlow";
  return (
    <div className={classNames(vertical ? "ml-4 mr-12" : "text-center")}>
      <div className={classNames("truncate", styles[variant].descriptionText)}>
        {!uploadedFile
          ? isGoFlow
            ? ""
            : "Uploading file..."
          : uploadedFile.fileName}
      </div>
      <div className={classNames("text-sm", styles[variant].descriptionText)}>
        {!isGoFlow &&
          (!uploadedFile
            ? `${(uploading || 0).toFixed(0)} %`
            : `${uploadedFile.contentType} — ${biteSize(uploadedFile.size, {
                precision: 0,
              })}`)}
        {uploadedFile && onDelete && !readonly && (
          <div className="absolute bottom-4 right-4">
            <Button white square onClick={onDelete}>
              <TrashIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

interface FileListItemInfoProps {
  file: UploadFileInfo;
  onDelete: () => void;
  onCancel: () => void;
  onRetry: () => void;
  readonly?: boolean;
  variant: Variant;
}

export const FileListItemInfo: React.FC<FileListItemInfoProps> = ({
  onDelete,
  onCancel,
  onRetry,
  file: { uploading, error, fileInput, uploadedFile, cancelled },
  readonly,
  variant,
}) => {
  const getFileStatus = useCallback(() => {
    if (error)
      return (
        <>
          <div className="mr">{error}</div>
        </>
      );

    if (uploadedFile)
      return (
        <>
          <div>Success</div>
          <CheckIcon className="mx-1.5 w-12" />
        </>
      );

    if (cancelled) {
      return <div className="mx-2.5">Upload cancelled</div>;
    }

    return (
      <>
        {isNumber(uploading) && <div>{`${uploading.toFixed(2)}%`}</div>}
        <LoadingSpinner height="36" width="36" variant="transparent" />
      </>
    );
  }, [cancelled, error, uploadedFile, uploading]);

  return (
    <div className="flex">
      <div
        className={classNames(
          "text-center h-16 flex rounded-xl items-center p-2 text-sm flex-1 w-full overflow-hidden min-w-0",
          styles[variant].borderLight
        )}
      >
        <div className="flex flex-col mr-auto text-left ml-4 h-12 justify-between min-w-0">
          <div className="truncate">
            {uploadedFile?.fileName || fileInput?.name}
          </div>
          <div
            className={classNames(styles[variant].descriptionText, "text-xs")}
          >{`${sizeInMB(uploadedFile?.size || fileInput?.size).toFixed(
            2
          )} MB`}</div>
        </div>
        {getFileStatus()}
      </div>
      <div className="ml-2 flex items-center w-10">
        {uploadedFile && onDelete && !readonly && (
          <Button white square onClick={onDelete}>
            <TrashIcon
              className={classNames(styles[variant].descriptionText)}
            />
          </Button>
        )}
        {isNumber(uploading) && (
          <Button white square onClick={onCancel}>
            <CloseIcon />
          </Button>
        )}
        {(cancelled || error) && (
          <Button white square onClick={onRetry}>
            <ReloadIcon
              className={classNames(styles[variant].descriptionText)}
            />
          </Button>
        )}
      </div>
    </div>
  );
};

const Icon = ({
  uploading,
  uploadedFile,
  vertical,
  isGoFlow,
}: {
  isGoFlow: boolean;
  vertical?: boolean;
  uploading: null | number;
  uploadedFile: any;
}) =>
  uploading !== null && !uploadedFile ? (
    <img
      src={`/images/spinner_web_96px_transparent.gif`}
      width="40"
      height="40"
      className={classNames(
        vertical ? "-mx-2" : "mx-auto",
        isGoFlow && "ml-2.5"
      )}
    />
  ) : (
    <DocumentIcon
      className={classNames(vertical ? "w-6 h-6 shrink-0" : "w-8 h-8")}
    />
  );

const UploadButton = ({
  onFileSelected,
  error,
  vertical,
  readonly,
  multiple = false,
  variant,
  placeholder,
  customButton,
}) => {
  if (customButton)
    return (
      <FileInput
        readonly={readonly}
        className={classNames("cursor-pointer text-sm")}
        onChange={onFileSelected}
        multiple={multiple}
      >
        {customButton}
      </FileInput>
    );

  return (
    <div
      className={classNames(
        "flex flex-col",
        vertical ? "ml-4" : "items-center"
      )}
    >
      <div className={styles[variant].text}>
        {placeholder}
        <FileInput
          readonly={readonly}
          className={classNames(
            "underline cursor-pointer",
            styles[variant].actionText
          )}
          onChange={onFileSelected}
          multiple={multiple}
        >
          browse
        </FileInput>
      </div>
      <div className={(classNames(styles[variant].descriptionText), "text-sm")}>
        Max size per file is {limitInMB}mb
      </div>
      {error && <div className="text-sm text-center text-error">{error}</div>}
    </div>
  );
};
