import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const TrashIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 3C9.44772 3 9 3.44772 9 4V5H15V4C15 3.44772 14.5523 3 14 3H10Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6H20V8H18.8667L18 21H6L5.13333 8H4V6ZM7.13777 8H16.8622L16.1289 19H7.87111L7.13777 8Z"
      />
    </svg>
  );
};

export default TrashIcon;
