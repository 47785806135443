import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CheckIcon: FC<SVGIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3241 14.2028C10.1288 14.3981 9.81222 14.3981 9.61695 14.2028L7.06062 11.6465C6.86536 11.4512 6.54878 11.4512 6.35352 11.6465L5.64641 12.3536C5.45115 12.5489 5.45115 12.8654 5.64641 13.0607L9.61695 17.0313C9.81221 17.2265 10.1288 17.2265 10.3241 17.0313L18.8093 8.54598C19.0046 8.35071 19.0046 8.03413 18.8093 7.83887L18.1022 7.13176C17.907 6.9365 17.5904 6.9365 17.3951 7.13176L10.3241 14.2028Z"
      />
    </svg>
  );
};

export default CheckIcon;
