import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CloseIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.98959 6.69682C5.79432 6.89208 5.79432 7.20866 5.98959 7.40392L10.5858 12.0001L5.98959 16.5963C5.79432 16.7916 5.79432 17.1082 5.98959 17.3034L6.69669 18.0105C6.89196 18.2058 7.20854 18.2058 7.4038 18.0105L12 13.4143L16.5962 18.0105C16.7914 18.2058 17.108 18.2058 17.3033 18.0105L18.0104 17.3034C18.2057 17.1082 18.2057 16.7916 18.0104 16.5963L13.4142 12.0001L18.0104 7.40392C18.2057 7.20866 18.2057 6.89208 18.0104 6.69682L17.3033 5.98971C17.108 5.79445 16.7914 5.79445 16.5962 5.98971L12 10.5859L7.4038 5.98971C7.20854 5.79445 6.89196 5.79445 6.69669 5.98971L5.98959 6.69682Z" />
    </svg>
  );
};

export default CloseIcon;
