import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ReloadIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8 12C2.8 8.02355 6.02355 4.8 10 4.8C10.4971 4.8 10.9 4.39706 10.9 3.9C10.9 3.40294 10.4971 3 10 3C5.02944 3 1 7.02944 1 12C1 16.9706 5.02944 21 10 21C14.789 21 18.7044 17.2595 18.984 12.5407L20.607 14.1637C20.8023 14.359 21.1189 14.359 21.3141 14.1638L21.8817 13.5962C22.0769 13.4009 22.0769 13.0844 21.8817 12.8891L18.3462 9.35356C18.1509 9.1583 17.8343 9.1583 17.6391 9.35356L14.1036 12.8891C13.9083 13.0844 13.9083 13.4009 14.1036 13.5962L14.6711 14.1638C14.8664 14.359 15.183 14.359 15.3782 14.1638L17.1916 12.3503C17.0089 16.1641 13.859 19.2 10 19.2C6.02355 19.2 2.8 15.9764 2.8 12Z"
    />
  </svg>
);

export default ReloadIcon;
