import { useCallback, useRef } from "react";

export const FileInput = ({
  accept,
  className,
  onChange,
  children,
  readonly,
  multiple,
  Component = "label",
}) => {
  const ref = useRef();

  const handleChange = useCallback(
    (e) => {
      onChange(e);
      ref.current.value = "";
    },
    [onChange]
  );

  return (
    <Component className={className}>
      {children}
      <input
        disabled={readonly}
        type="file"
        accept={accept}
        ref={ref}
        multiple={multiple}
        className="hidden focus:ring-0"
        onChange={handleChange}
      />
    </Component>
  );
};
