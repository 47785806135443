import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import AlertIcon from "./Icons/AlertIcon";

interface ErrorMessageProps {
  className?: string;
  children: ReactNode;
  iconClassName?: string;
  warning?: boolean;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({
  className,
  children,
  iconClassName,
  warning = false,
}) => (
  <div className="rounded-md bg-white">
    <div
      className={classNames(
        "text-sm font-normal rounded-md flex items-center py-3 px-4",
        warning ? "bg-action-900 text-action-500" : "bg-error/20 text-error",
        className
      )}
    >
      <AlertIcon className={classNames("w-6 h-6", iconClassName)} />
      <span className="ml-2 flex-1">{children}</span>
    </div>
  </div>
);
