import { FC } from "react";

import { Button } from "@components/Button";
import { SmallModalProps } from "@components/Modals/SmallModal";

interface SmallModalButtonProps {
  iconColor: SmallModalProps["iconColor"];
  onAction: SmallModalProps["onAction"];
  onActionText: SmallModalProps["onActionText"];
  isActionDisabled?: SmallModalProps["isActionDisabled"];
  onActionLoading?: SmallModalProps["onActionLoading"];
  modalName: string;
  primary?: boolean;
}

const SmallModalButton: FC<SmallModalButtonProps> = ({
  iconColor = "blue",
  onAction,
  onActionText,
  isActionDisabled = false,
  onActionLoading = false,
  modalName,
  primary = false,
}) => {
  const content = (
    <Button
      className="w-full"
      primary={primary && iconColor !== "peach"}
      peach={primary && iconColor === "peach"}
      whitePeach={!primary && iconColor === "peach"}
      onClick={onAction}
      disabled={isActionDisabled}
      isLoading={onActionLoading}
      data-heap-event-name={`${modalName}_${onActionText}_button_clicked`}
    >
      {onActionText}
    </Button>
  );

  return content;
};

export default SmallModalButton;
