import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8H7.99997V10H12V8Z" />
      <path d="M16 12V14H7.99997V12H16Z" />
      <path d="M16 17V15H7.99997V17H16Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.99997C4 2.44769 4.44772 1.99997 5 1.99997H16C18.2091 1.99997 20 3.79083 20 5.99997V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V2.99997ZM6 20V3.99997H16C17.1046 3.99997 18 4.8954 18 5.99997V20H6Z"
      />
    </svg>
  );
};

export default Icon;
